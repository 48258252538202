import axios from 'axios';
import myAxios from '@/utils';
// import VueAzureUploader from 'vue-azure-blob-upload';

axios.defaults.maxBodyLength = Infinity;
axios.defaults.maxContentLength = Infinity;

const { CancelToken } = axios;
// const source = CancelToken.source();
const cancelsMap = new Map(); // 源文件初始名为可以 取消上传方法为value

const uploadServer = {
  // 获取上传文件的路径和token  linkType=media(媒体)image(图片) isMedia: boolean = false
  //   url: `/community-service/community/file/blobContainerSas?linkType=${typeStr}`,
  getUploadInfo: () => {
    // let typeStr = isMedia ? 'media' : 'image';
    return (myAxios as any)({ url: `/community-service/community/file/blobContainerSas`, method: 'get' });
  },
  upload: async (
    url: string,
    fileName: string,
    token: string,
    file: any,
    progressFun?: (progress: number) => void,
  ): Promise<boolean> => {
    console.log(file);
    let headers = { 'x-ms-blob-type': 'BlockBlob', 'Content-Type': file.type, 'x-ms-version': '2021-12-02' };
    if (file.type === 'video') {
      // @ts-ignore
      headers = { 'content-type': 'multipart/form-data' };
    }
    const result = await axios({
      url: `${url}/${fileName}?${token}`,
      method: 'put',
      maxContentLength: Infinity,
      data: file.raw,
      headers,
      onUploadProgress(progressEvent) {
        const pro = progressEvent.loaded / progressEvent.total;
        // Log('onUploadProgress');
        if (progressFun) {
          progressFun(pro);
        }
      },
      cancelToken: new CancelToken(function executor(c) {
        if (file?.raw?.name) {
          cancelsMap.set(file?.raw?.name, c);
        }

        // executor 函数接收一个 cancel 函数作为参数
      }),
      //   validateStatus: function (status) {
      //     Log('validateStatus', status);
      //     return status == 200; // 默认值
      //   },
    });

    if (cancelsMap.has(file?.raw?.name)) {
      //   Log('上传成功了,删除对应的key', file?.raw?.name);
      cancelsMap.delete(file?.raw?.name);
    }
    // Log('upload', result);

    // if (/\.(jpg|jpeg|png|GIF|JPG|PNG)$/.test(fileName)) {
    //   checkLinks([`${url}/${fileName}`]);
    // }
    return result?.status === 201;
    /*
        let uploadResult = await uploadServer.upload(clientUrl.value, fileName.value, containerSas.value, file);
        uploadResult是true和false
    */
  },

  cancelUpload: (key: string) => {
    // Log('cancelUpload', cancelsMap);
    // 源文件初始名
    if (cancelsMap.has(key)) {
      const cancelFunction = cancelsMap.get(key);
      cancelFunction('取消了上传');
    }
    if (cancelsMap.has(key)) {
      cancelsMap.delete(key);
    }
    // Log('cancelUpload', cancelsMap);
  },

  cancelAllUpload: () => {
    // Log('cancelAllUpload', cancelsMap);
    for (const cancel of cancelsMap.values()) {
      cancel('取消了上传');
    }
    cancelsMap.clear();
    // Log('cancelAllUpload', cancelsMap);
  },

  // base64转blob
  getBaseToBlob: (dataurl: string) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });

    // let audioSrc = baseStr; // 拼接最终的base64
    // let arr = audioSrc.split(',');
    // let array = arr[0].match(/:(.*?);/);
    // let mime = (array && array.length > 1 ? array[1] : type) || type;
    // // 去掉url的头，并转化为byte
    // let bytes = window.atob(arr[1]);
    // // 处理异常,将ascii码小于0的转换为大于0
    // let ab = new ArrayBuffer(bytes.length);
    // // 生成视图（直接针对内存）：8位无符号整数，长度1个字节
    // let ia = new Uint8Array(ab);
    // for (let i = 0; i < bytes.length; i++) {
    //   ia[i] = bytes.charCodeAt(i);
    // }
    // let blob = new Blob([ab], {
    //   type: mime,
    // });
  },
};

//   验证链接地址是否合规
const checkLinks = (urls: string[]) => {
  const params = {
    imageList: urls,
  };
  return (myAxios as any)({ url: '/community-service/community/file/async', method: 'post', data: params });
};

export { uploadServer };
