import myEditor from '@/pages/components/myEditor.vue';
import myEditorMath from '@/pages/components/myEditorMath.vue';
import myPhotoUpload from '@/pages/components/myPhotoUpload.vue';
import myUeditor from '@/pages/components/ueditor.vue';

export default {
  install(app) {
    // 富文本编辑器
    app.component('MyEditor', myEditor);
    app.component('MyEditorMath', myEditorMath);
    app.component('myUeditor', myUeditor);

    // 图片控件
    app.component('MyPhotoUpload', myPhotoUpload);
  },
};
